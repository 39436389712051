<template>
    <div class="search">

        <Head :centerInfo="false"></Head>
        <div class="subnavLeft">

            <ul>
                <li v-for="(item,index) in navList" @click="tabFn(item,index)" :key="index"
                    :class="listQuery.classID==item.classID?'activeSubnac':''">{{item.className}}</li>
            </ul>
        </div>

        <div class="search_content">
            <h4 v-if="navList.length!=0">{{navList[checkedIndex].className}}</h4>
            <p class="totalVideo">{{videoCnts}}个视频，{{goodVideoCnts}}个精选视频</p>
            <div class="search_box">
                <div class="searchBox_info" v-for="(item,index) in listData" :key="index">
                    <img @click="jump(item)"
                        :src="item.coverUrl" alt="">
                    <div class="videoDetail">
                        <p class="playNum">
                            <span class="iconfont icon-bofang"></span>
                            {{item.playNum}}
                        </p>
                        <p class="fabulousNum" @click="goodVFn(item,index)" :class="item.isMyGood == 1?'checkedzan':''">
                            <span class="iconfont icon-dianzan1"></span>
                            {{item.goodNum}}
                        </p>
                        <p class="videoTime">{{parseInt(parseInt(item.duration)/60)}}:{{parseInt(item.duration)%60}}</p>
                    </div>

                    <p class="searchVideo_title">{{item.videoTitle}}</p>
                    <p class="searchVideo_time">{{item.approveDate}}</p>
                </div>
            </div>
            
            <pagination v-show="total>0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.size"
                @pagination="getList" />
            <!--无数据-->
            <el-empty v-if="listData.length == 0" image="http://image.ourgame.com/videoWeb/null.png" description=" ">
            </el-empty>
        </div>
    </div>
</template>

<script>
    import pagination from '@/components/pagination.vue'
    import Head from '@/components/head.vue';
    import {
        getVideosecondyClass,
        getVideoejPageByClassID,
        goodVideo,
        cancelGoodVideo
    } from '@/api/authority'
    export default {
        name: "classification",
        components: {
            Head,
            pagination
        },
        data() {
            return {
                navList: [],
                listQuery: {
                    classID: '',
                    current: 1,
                    size: 20,
                },
                total: 0,
                checkedIndex: 0,
                goodVideoCnts: 0,
                videoCnts: 0,
                listData: [],
            }
        },
        mounted() {
            window.scrollTo(0,0)
            this.getVideosecondyClassFn()
        },
        methods: {
            jump(item){
                this.$store.commit('JUMP_PLAY',{alid:item.aliVideoId,videoId:item.videoID,vue:this})
            },
            //分类列表   
            getVideosecondyClassFn() {
                getVideosecondyClass().then(response => {
                    if (response.data.code == 0) {
                        this.navList = response.data.data

                        this.listQuery.classID = this.navList[0].classID
                        if (this.$route.query.cID) {
                            this.listQuery.classID = this.$route.query.cID
                        }
                        for (let i in this.navList) {
                            if (this.$route.query.cID == this.navList[i].classID) {
                                this.checkedIndex = i
                            }
                        }
                        this.getList()
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            //点赞   
            goodVFn(item, index) {
                if (item.isMyGood == 0) {
                    this.goodVideoFn(item.videoID, index)
                } else {
                    this.cancelGoodVideoFn(item.videoID, index)
                }
            },
            //取消点赞
            cancelGoodVideoFn(id, index) {
                cancelGoodVideo({
                    videoID: id
                }).then(response => {
                    if (response.data.code == 0) {
                        this.listData[index].goodNum--
                        this.listData[index].isMyGood = 0
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            //点赞
            goodVideoFn(id, index) {
                goodVideo({
                    videoID: id
                }).then(response => {
                    if (response.data.code == 0) {
                        this.listData[index].goodNum++
                        this.listData[index].isMyGood = 1
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 切换列表
            tabFn(item, index) {
                this.listQuery.classID = item.classID
                this.checkedIndex = index
                this.listQuery.current = 1
                this.getList()
            },
            // 根据分类查询   
            getList(parm) {
                if (typeof parm != 'undefined') {
                    this.listQuery.current = parm.page
                }
                getVideoejPageByClassID(this.listQuery).then(response => {
                    if (response.data.code == 0) {
                        this.listData = response.data.data.videoDTOPage.records
                        this.goodVideoCnts = response.data.data.goodVideoCnts
                        this.videoCnts = response.data.data.videoCnts
                        this.total = response.data.data.videoDTOPage.total
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            }
        }
    }
</script>
<style lang="less">
    .activeSubnac {
        background-color: #fafafa;
    }

    .checkedzan {
        color: #ff8a00 !important;
    }
            .search_box {
                margin: -16px 0px 0px 222px;
                width: 1249px;
                box-sizing: border-box;

                //    padding-left:246px;
                .searchBox_info {
                    margin: 16px 0px 0px 24px;
                    //    border:9px solid black;
                    overflow: hidden;
                    position: relative;
                    color: white;
                    background: white;
                    width: 224px;
                    float: left;

                    img {
                        width: 224px;
                        height: 178px;
                        cursor: pointer;
                    }

                    .videoDetail {
                        position: absolute;
                        font-size: 12px;
                        top: 158px;
                        width: 224px;
                        left: 0px;
                        cursor: pointer;

                        p {
                            color: white;
                            float: left;
                            padding-left: 9px;

                            &:hover {
                                color: #ff8a00
                            }
                        }

                        .fabulousNum {
                            padding-left: 14px;
                        }

                        .videoTime {
                            float: right;
                            padding-right: 9px;

                            &:hover {
                                color: white;
                            }
                        }
                    }

                    .searchVideo_title {
                        font-size: 14px;
                        color: #333333;
                        padding: 15px 0px 0px 9px;
                        width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .searchVideo_time {
                        padding: 15px 0px 8px 9px;
                        color: #b8b8b8;
                        font-size: 12px;
                    }
                }
            }
    .search {
        .search_content {

            >h4 {
                padding: 40px 0px 10px 247px;
                color: #333333;
                font-size: 18px;
            }

            .totalVideo {
                color: #b8b8b8;
                font-size: 12px;
                text-indent: 247px;
                padding-bottom: 20px;

            }


        }
    }
</style>